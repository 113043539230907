import { useEffect } from 'react'

import { useProposal, useLead } from '@itau-loans-www/shopping/src/hooks'
import platform from 'platform'
import { goTo, getDevice, isClient } from 'utils'

import services from './services'

export const useAllowFgts = () => {
  const { loading, setIsLoading } = useProposal()
  const { os } = platform

  const { changeLeadData } = useLead()

  const alreadyAuthorized = async () => {
    setIsLoading(true)
    return services
      .postFgtsAllowed()
      .then((nextStep) => goTo(nextStep))
      .finally(() => setIsLoading(false))
  }

  const getLink = () => {
    const isMobile = getDevice.isMobile()
    const link = {
      desktop:
        'https://www.caixa.gov.br/atendimento/aplicativos/fgts/Paginas/default.aspx',
      Android:
        'https://play.google.com/store/apps/details?id=br.gov.caixa.fgts.trabalhador',
      iOS: 'https://apps.apple.com/br/app/fgts/id1038441027'
    }

    if (!isMobile) return link.desktop

    return link[os.family]
  }

  const knowMore = () => {
    if (isClient()) {
      const link = getLink()
      window.open(link, '_blank')
    }
  }

  useEffect(() => {
    changeLeadData({ previousStep: 'home' })
  }, [])

  useEffect(() => {
    setIsLoading(false)
  }, [setIsLoading])

  return { loading, alreadyAuthorized, knowMore }
}
